import type { Mediator } from 'types/mediator'
import type { DataTypeEnum$options, HOUDINI_inventoryQuery$result as InventoryResult, LIB_settingValues$result, LIB_storeLocations$result, PartScreenStageOne$result } from '$houdini'
import type { AppContext } from 'types/common'
import type { Merge, WritableDeep } from 'type-fest'
import type { BaseAttachmentFile } from '@isoftdata/svelte-attachments'

// we do a lot of post processing to change the shape of the inventory object, so this is the type before we do that
type InventoryFromApi = Omit<WritableDeep<InventoryResult['inventory']>, ' $fragments'>
export type SettingValues = LIB_settingValues$result['settingValues']

import pProps from 'p-props'
import component from './Part.svelte'
import { getSession } from 'stores/session'
import { graphql } from '$houdini'
import loadModelsForManufacturer, { type PartModel } from 'utility/load-models-for-manufacturer'
import { klona } from 'klona'
import { v4 as uuid } from '@lukeed/uuid'
import { stringToBoolean } from '@isoftdata/utility-string'
import ObjectMap from '../../classes/ObjectMap'
import documentTypes, { type DocumentTypeName } from '../../common/document-types'
import toTitleCase from 'to-title-case'
import { getObject } from '@isoftdata/utility-storage'
import loadVehicleModelsForMake from 'utility/load-models-for-make'
import { writable } from 'svelte/store'

// #region Constants
export type TypeField = {
	label: string
	value: string | null
	history: Array<string>
}
export type OptionValueMap = ObjectMap<{ optionId: number; serialId?: number | null; serialUuid?: string | null; valueWasDefault?: boolean }, string | number | boolean | null>
export type PartStoreLocations = LIB_storeLocations$result['locations']
export type PartForClient = Merge<
	Omit<InventoryFromApi, 'attachments'>,
	{
		category: InventoryFromApi['category'] | null
		defaultVendor: InventoryFromApi['defaultVendor'] | null
		enteredBy: {
			id: number
			name: string
		} | null
		innodbInventoryid: number | null
		inventoryId: number | null
		// jobberPrice: number
		maxQuantity: number
		minQuantity: number
		popularityCode: string
		sellPriceClassId: number | null
		subInterchangeNumber: string
		locations: Array<
			Merge<
				Merge<InventoryFromApi['locations'][number]['location'], Omit<InventoryFromApi['locations'][number], 'location'>>,
				{
					id: number | null
					quantity: number
					holdQuantity: number
					uuid: string
					deleted: boolean
				}
			>
		>
		inventoryType: WritableDeep<InventoryFromApi>['inventoryType'] | null
		inventoryTypeId: number | null
		inventoryOptions: Array<FlatQuestion>
		newSerial: ''
		quantity: number
		quantityAvailable: number
		quantityOnHold: number
		safetyStockPercent: number
		serials: Array<
			Merge<
				Omit<InventoryFromApi['serials'][number], ' $fragments'>,
				{
					inventoryOptions: Array<FlatQuestion>
					uuid: string
					usedOn: string
					source: string
					displayStatus: string
					status: string
					deleted?: boolean
					location: {
						id: number | null
						name: string
						description: string | null
						unsaved?: boolean
					} | null
				}
			>
		>
		sku: number | null
		storeId: number | null
		tagPrinted: boolean
		// vendorLeadTime: string
	}
>

// Please keep this alphabetical :)
// These should be the same values as we keep in memory, not necessarily the same as the API.
const defaultPart = Object.freeze<PartForClient>({
	averageCoreCost: '0',
	averageCost: '',
	averageDemandPerDay: 'N/A',
	averageDemandPerMonth: 'N/A',
	bodyStyle: '',
	buyPackage: 1,
	category: null,
	condition: '',
	coreClass: '',
	coreCost: '0',
	coreRequired: false,
	coreRequiredToVendor: false,
	cost: '0',
	dateEntered: new Date(),
	dateModified: new Date(),
	dateViewed: new Date(),
	daysToReturn: 30,
	daysToReturnCore: 30,
	daysToReturnCoreToVendor: 30,
	daysToReturnToVendor: 30,
	defaultVendor: null,
	deplete: true,
	description: '',
	enteredBy: null,
	freezeUntil: null,
	glCategory: null,
	innodbInventoryid: null,
	inventoryId: null,
	inventoryOptions: [],
	inventoryType: null,
	inventoryTypeId: null,
	jobberCorePrice: '0',
	jobberPrice: '0',
	locations: [],
	maxQuantity: 1,
	minQuantity: 1,
	model: null,
	// TODO, probably doesn't need to be on the part object?
	newSerial: '',
	notes: '',
	oemNumber: '',
	partNumber: '',
	parentManufacturer: null,
	parentModel: null,
	popularityCode: '',
	printTag: null,
	public: false,
	quantity: 1,
	quantityAvailable: 1,
	quantityOnHold: 0,
	replenishable: false,
	retailCorePrice: '0',
	retailPrice: '0',
	returnable: true,
	returnableToVendor: true,
	safetyStockPercent: 0,
	saleClass: { code: 'NONE', name: 'NONE' },
	seasonal: false,
	sellPackage: 1,
	sellPriceClassId: null,
	serials: [],
	serialized: false,
	shippingDimensions: {
		height: '0',
		length: '0',
		measurementUnit: 'IN',
		weight: '0',
		weightUnit: 'LB',
		width: '0',
	},
	side: 'NA',
	singleQuantity: false,
	sku: null,
	status: 'A',
	stockCategory: 'MISC',
	stockingDays: 0,
	stockMethod: 'NONE',
	stockType: 'SPECIAL_ORDER',
	storeId: null,
	subInterchangeNumber: '',
	suggestedMaxQuantity: '',
	suggestedMinQuantity: '',
	suggestedSafetyStockPercent: '',
	tagNumber: '',
	tagPrinted: false,
	taxable: true,
	topImage: null,
	typeField1: {
		data: '',
		label: '',
	},
	typeField2: {
		data: '',
		label: '',
	},
	typeField3: {
		data: '',
		label: '',
	},
	typeField4: {
		data: '',
		label: '',
	},
	userStatus: '',
	useVendorOrderMultiplier: false,
	vendorLeadTime: 0,
	vendorPopularityCode: '',
	vendorProductCode: '',
	vehicle: null,
	vehicleId: null,
	vehicleMake: '',
	vehicleModel: '',
	vehicleVin: null,
	vehicleYear: null,
	wholesaleCorePrice: '0',
	wholesalePrice: '0',
	interchangeNumber: null,
	listPrice: '0',
	manufacturer: null,
	distributorCorePrice: '0',
	distributorPrice: '0',
})
// #endregion
// #region Function definitions

function isValidQuestionForPart(
	question: FlatQuestion,
	part: {
		manufacturerId?: number | null
		modelId?: number | null
		categoryName?: string | null
		inventoryTypeId?: number | null
	}
) {
	return (
		(question.manufacturerId === part.manufacturerId || question.manufacturerId === null) &&
		(question.modelId === part.modelId || question.modelId === null) &&
		(question.categoryName?.toLowerCase() === part.categoryName?.toLowerCase() || question.categoryName === null) &&
		(question.inventoryTypeId === part.inventoryTypeId || question.inventoryTypeId === null)
	)
}

function transformInventoryOptionValue(dataType: DataTypeEnum$options, value: string | null) {
	if (dataType === 'NUMBER') {
		return value === null || value === '' ? null : Number(value)
	} else if (dataType === 'BOOLEAN') {
		return stringToBoolean(value ?? '')
	} else {
		return value
	}
}

function getOptionValueMap(part: PartForClient) {
	type KeyObj = { optionId: number; serialId?: number | null; serialUuid?: string }
	type OptionValue = (typeof part.inventoryOptions)[number]['value']
	type EntriesArray = Array<[KeyObj, OptionValue]>
	const inventoryOptionsForMap: Array<[KeyObj, OptionValue]> = part.inventoryOptions
		.filter(option => option.value) // don't include empty string or null values
		.map(option => [{ optionId: option.id }, option.value])

	const entries: EntriesArray = part.serialized
		? part.serials
				.reduce((acc: Array<[KeyObj, OptionValue]>, serial) => {
					return acc.concat(
						serial.inventoryOptions.map(option => [
							{
								serialId: serial.id,
								optionId: option.id,
								serialUuid: serial.uuid,
							},
							option.value,
						])
					)
				}, [])
				.concat(inventoryOptionsForMap)
		: inventoryOptionsForMap
	return new ObjectMap(
		['serialId', 'optionId', 'serialUuid'], // always all key parts, in case we serialize later
		entries
	)
}

function flattenInventoryOptionForDisplay({ option, value }: InventoryFromApi['inventoryOptions'][number]) {
	const { category, manufacturer, model, inventoryType, ...optionRest } = option
	return {
		...optionRest,
		value: transformInventoryOptionValue(option.dataType, value),
		categoryId: category?.id ?? null,
		categoryName: category?.name ?? null,
		manufacturerId: manufacturer?.id ?? null,
		modelId: model?.id ?? null,
		inventoryTypeId: inventoryType?.id ?? null,
	}
}
export type FlatQuestion = ReturnType<typeof flattenInventoryOptionForDisplay>

function flattenAttachment(item: InventoryFromApi['attachments'][number]): BaseAttachmentFile {
	return {
		...item.file,
		fileId: item.fileId,
		public: item.public,
		rank: item.rank ?? 0,
		createdDate: item.file.createdDate.toISOString(),
		uuid: uuid(),
	}
}

export type FlatAttachment = ReturnType<typeof flattenAttachment>

function formatUsedOnDocument({
	usedOnDocumentId,
	usedOnDocumentStoreId,
	usedOnDocumentType,
}: {
	usedOnDocumentId: number | null
	usedOnDocumentStoreId: number | null
	usedOnDocumentType: DocumentTypeName | null
}) {
	if (!usedOnDocumentType) {
		return ''
	}

	const documentType = documentTypes[usedOnDocumentType]
	// Don't bother showing line ids, just show the document type / id
	let str = `${documentType.parentAbbreviation ?? documentType.abbreviation} `
	if (usedOnDocumentStoreId) {
		str += `${usedOnDocumentStoreId}-`
	}
	if (usedOnDocumentId) {
		str += usedOnDocumentId
	}
	return str
}

function formatSourceDocument({
	enteredOnDocumentId,
	enteredOnDocumentStoreId,
	enteredOnDocumentType,
}: {
	enteredOnDocumentId: number | null
	enteredOnDocumentStoreId: number | null
	enteredOnDocumentType: DocumentTypeName | null
}) {
	return formatUsedOnDocument({
		usedOnDocumentId: enteredOnDocumentId,
		usedOnDocumentStoreId: enteredOnDocumentStoreId,
		usedOnDocumentType: enteredOnDocumentType,
	})
}
// incorrect but good enough for now
type CachedPart = Omit<PartForClient, 'attachments'>

type CachedAttachments = Array<{
	uuid: string
	fileId: null
	public: boolean
	rank: number
	mimeType: string
	name: string
	size: number
	path: string
	action: 'CREATE'
}>

function formatSerialLocation(serial: InventoryFromApi['serials'][number]) {
	return serial.location?.__typename === 'Location'
		? {
				id: serial.location.id,
				name: serial.location.name,
				description: serial.location.description,
		  }
		: serial.location?.__typename === 'VirtualLocation'
		? {
				id: null,
				name: serial.location.name,
				description: '',
		  }
		: null
}

function computeTypeFields(part: PartForClient) {
	if (!part.inventoryType) {
		return []
	}
	const typeFields = [
		//
		part.typeField1,
		part.typeField2,
		part.typeField3,
		part.typeField4,
	] as const

	const histories = [part.inventoryType?.typeData1History, part.inventoryType?.typeData2History, part.inventoryType?.typeData3History, part.inventoryType?.typeData4History]

	return typeFields.reduce((arr, field, index) => {
		if (field?.label) {
			arr.push({
				label: field.label,
				value: field.data,
				history: histories[index],
			})
		}
		return arr
	}, new Array<TypeField>())
}

async function loadPart(
	_mediator: Mediator,
	innodbInventoryid: number | null,
	loadCachedPart: boolean,
	settingValues: SettingValues
): Promise<{ part: PartForClient; attachments: Array<FlatAttachment> }> {
	console.log('loadPart', innodbInventoryid, loadCachedPart)
	const cachedPart = getObject<CachedPart>(localStorage, 'cachedPart') || false
	const cachedAttachments = getObject<CachedAttachments>(localStorage, 'cachedPartAttachments') ?? []
	console.log('cachedAttachments', cachedAttachments)
	if (loadCachedPart && cachedPart) {
		return {
			part: cachedPart,
			attachments: cachedAttachments.map(attachment => {
				const decode = attachment.path?.startsWith('data:') ?? false
				return {
					...attachment,
					fileId: attachment.fileId ?? undefined,
					File: decode ? decodeFileFromCache(attachment) : undefined,
				}
			}),
		}
	}
	if (innodbInventoryid) {
		const { data } = await inventoryQueryByIdStore.fetch({
			variables: {
				innodbInventoryid,
				serialFilter: { statuses: ['AVAILABLE', 'ON_HOLD', 'IN_TRANSIT'] },
			},
		})

		if (!data) {
			throw new Error('No data returned from inventoryQueryByIdStore')
		}

		const part: PartForClient = {
			...data.inventory,
			enteredBy: data.inventory.enteredBy,
			locations: data.inventory.locations
				? // TODO, do I really have to flatten this?
				  data.inventory.locations
						.map(({ location, ...inventoryLocation }) => {
							location ??= { name: '', description: '', allowInventory: null }
							return {
								...location,
								...inventoryLocation,
								quantity: parseFloat(inventoryLocation.quantity),
								holdQuantity: parseFloat(inventoryLocation.holdQuantity),
								uuid: uuid(),
								deleted: false,
							}
						})
						.sort((a, b) => a.rank ?? 0 - (b.rank ?? 0))
				: [],
			// On a serialized part, this will be the default serial Q&A. On a non-serialized part, this is the only Q&A.
			inventoryOptions: [],
			singleQuantity: data.inventory.singleQuantity ?? false, // temp fix for missing API field
			tagPrinted: !data.inventory.printTag,
			minQuantity: parseFloat(data.inventory.minQuantity),
			maxQuantity: parseFloat(data.inventory.maxQuantity),
			quantity: parseFloat(data.inventory.quantity),
			quantityOnHold: parseFloat(data.inventory.quantityOnHold),
			quantityAvailable: parseFloat(data.inventory.quantityAvailable),
			defaultVendor: data.inventory.defaultVendor ?? null,
			safetyStockPercent: parseFloat(data.inventory.safetyStockPercent),
			vendorLeadTime: data.inventory.vendorLeadTime,
			serials: [],
			sellPriceClassId: null,
			subInterchangeNumber: '',
			newSerial: '',
		}

		if (!part.serialized) {
			// This isn't used for saving anymore, but it's used for the UI
			part.newSerial = ''
		} else {
			part.serials =
				data.inventory.serials
					.map(serial => {
						return {
							...serial,
							inventoryOptions: serial.inventoryOptions.map(flattenInventoryOptionForDisplay),
							uuid: uuid(),
							usedOn: formatUsedOnDocument(serial),
							source: formatSourceDocument(serial),
							displayStatus: toTitleCase(serial.status ?? ''),
							status: serial.status ?? '',
							location: formatSerialLocation(serial),
						}
					})
					.sort((a, b) => a.status.localeCompare(b.status) || a.number.localeCompare(b.number)) ?? []
		}

		// On a serialized part, this will be the default serial Q&A. On a non-serialized part, this is the only Q&A.
		part.inventoryOptions = (data.inventory.inventoryOptions ?? [])
			.map(flattenInventoryOptionForDisplay)
			.filter(option => isValidQuestionForPart(option, { ...part, categoryName: part.category?.name ?? null }))
			.sort((a, b) => a.rank - b.rank)

		// cached/new part will already have this done to it
		part.locations = data.inventory.locations
			? // uuid is so we have a unique key for the stepper buttons, even on unsaved locations
			  data.inventory.locations
					.map(({ location, ...inventoryLocation }) => {
						location ??= { name: '', description: '', allowInventory: null }
						return {
							...location,
							...inventoryLocation,
							quantity: parseFloat(inventoryLocation.quantity),
							holdQuantity: parseFloat(inventoryLocation.holdQuantity),
							uuid: uuid(),
							deleted: false,
						}
					})
					.sort((a, b) => a.rank ?? 0 - (b.rank ?? 0))
			: []
		if (!part.serialized) {
			// This isn't used for saving anymore, but it's used for the UI
			part.newSerial = ''
		} else {
			part.serials =
				data.inventory.serials
					.map(serial => {
						return {
							...serial,
							inventoryOptions: serial.inventoryOptions.map(flattenInventoryOptionForDisplay),
							uuid: uuid(),
							usedOn: formatUsedOnDocument(serial),
							source: formatSourceDocument(serial),
							displayStatus: toTitleCase(serial.status ?? ''),
							status: serial.status ?? '',
							location: formatSerialLocation(serial),
						}
					})
					.sort((a, b) => a.status.localeCompare(b.status) || a.number.localeCompare(b.number)) ?? []
		}
		part.singleQuantity = part.singleQuantity ?? false // temp fix for missing API field
		part.tagPrinted = !part.printTag
		// hopefully fix trailing 0 issue
		// part.sellPackage = parseFloat(data.inventory.sellPackage)
		// part.buyPackage = parseFloat(part.buyPackage)
		part.minQuantity = parseFloat(data.inventory.minQuantity)
		part.maxQuantity = parseFloat(data.inventory.maxQuantity)
		part.quantity = parseFloat(data.inventory.quantity)
		part.quantityOnHold = parseFloat(data.inventory.quantityOnHold)
		part.quantityAvailable = parseFloat(data.inventory.quantityAvailable)
		part.defaultVendor = part.defaultVendor ?? null

		return {
			part,
			attachments: data.inventory.attachments?.map(flattenAttachment) ?? [],
		}
	}

	return {
		part: {
			...klona(defaultPart),
			daysToReturn: settingValues.inventory.defaultDaysToReturn ?? 0,
			daysToReturnCore: settingValues.inventory.defaultDaysToReturnCore ?? 0,
			daysToReturnCoreToVendor: settingValues.inventory.defaultDaysToReturnCoreToVendor ?? 0,
			daysToReturnToVendor: settingValues.inventory.defaultDaysToReturnToVendor ?? 0,
			returnable: settingValues.inventory.defaultReturnable,
			// we set a fake object wit the id, and then .find from the list in resolve
			glCategory: { id: settingValues.inventory.defaultGlCategoryId, name: '' },
			returnableToVendor: settingValues.inventory.defaultReturnableToVendor,
		},
		attachments: [],
	}
}

function decodeFileFromCache({ path, ...attachment }: { path: string; name: string }) {
	const [meta, base64] = path.split(',')
	const [, mimeType] = meta.match(/^data:(.*);base64$/) ?? []
	const byteCharacters = atob(base64)

	const byteNumbers = new Array(byteCharacters.length)
	for (let i = 0; i < byteCharacters.length; i++) {
		byteNumbers[i] = byteCharacters.charCodeAt(i)
	}

	const byteArray = new Uint8Array(byteNumbers)

	return new File([byteArray], attachment.name, { type: mimeType })
}

// #endregion
export default function createState({ mediator, stateRouter, checkSessionPermission }: AppContext) {
	const stateName = 'app.part'

	stateRouter.addState({
		name: stateName,
		route: 'part/:storeId/:inventoryId',
		querystringParameters: ['inventoryId', 'storeId', 'lastSavedTime', 'lastResetTime', 'loadCachedPart'],
		defaultParameters: {
			inventoryId: null,
			storeId() {
				return getSession()?.currentStore?.toString() ?? null
			},
			tab: 'basic',
			lastResetTime: null, // only used to trigger a state reload
			loadCachedPart: 'false',
		},
		canLeaveState(domApi) {
			console.log('allow state change?', domApi)

			// @ts-expect-error todo
			if (domApi.partChanged) {
				return confirm('You have unsaved changes. Are you sure you want to leave?')
			}
			return true
		},
		template: {
			svelte: true,
			component,
		},
		async resolve(data, { inventoryId, tab, ...params }) {
			if (!checkSessionPermission('PARTS_CAN_VIEW_PARTS')) {
				alert('You do not have permission to view parts.')
				throw {
					redirectTo: {
						name: 'app',
					},
				}
			}
			console.log('params', params)
			const storeId = parseInt(params.storeId, 10)
			const loadCachedPart = stringToBoolean(params.loadCachedPart)
			const hasCachedPart = !!getObject(localStorage, 'cachedPart') || false
			// if we don't have a cached part, but we're trying to load one, don't
			if (loadCachedPart && !hasCachedPart) {
				localStorage.removeItem('cachedPart')
				localStorage.removeItem('cachedOptionValues')
				localStorage.removeItem('cachedPartAttachments')
				void mediator.publish('removeActivity', 'Unsaved Part')
				throw {
					redirectTo: {
						name: null,
						params: {
							inventoryId,
							storeId,
							loadCachedPart: false,
						},
					},
				}
			}
			const innodbInventoryid = parseInt(inventoryId, 10) || null

			const { data: settingValuesData } = await settingValuesQuery.fetch({ policy: 'CacheOrNetwork' })

			if (!settingValuesData) {
				throw new Error('No data returned from settingValuesQuery')
			}

			const settingValues = settingValuesData.settingValues

			const { part, attachments } = await loadPart(mediator, innodbInventoryid, loadCachedPart, settingValues)
			part.storeId ??= storeId

			const { data: stageOneData }: { data: WritableDeep<PartScreenStageOne$result> | null } = await stageOneQuery.fetch({ policy: 'CacheOrNetwork' })

			if (!stageOneData) {
				throw new Error('No data returned from stageOneQuery')
			}

			const stageTwoLoads: {
				partStoreLocations: ReturnType<typeof partStoreLocationsQuery.fetch>
				trueModels?: Promise<Array<PartModel>>
				assyModels?: Promise<Array<PartModel>>
				vehicleModels?: Promise<Array<string>>
			} = {
				// locations at the store the part is at. Used when adding a new location to the part
				partStoreLocations: partStoreLocationsQuery.fetch({
					variables: {
						storeIds: [part.storeId],
					},
					policy: 'CacheOrNetwork',
				}),
			}

			if (part.manufacturer && part.inventoryTypeId) {
				stageTwoLoads.trueModels = loadModelsForManufacturer(part.manufacturer.id, part.inventoryTypeId)
			}

			if (part.parentManufacturer && part.inventoryType?.setId) {
				stageTwoLoads.assyModels = loadModelsForManufacturer(part.parentManufacturer.id, part.inventoryType.setId)
			}

			if (part.vehicleMake) {
				stageTwoLoads.vehicleModels = loadVehicleModelsForMake(part.vehicleMake)
			}
			// Used to track which options are/were shown so defaults are only applied to new options
			// const existingOptionIdsSet = new Set(part?.inventoryOptions?.map(option => option.id))

			const { partStoreLocations, trueModels, assyModels } = (await pProps(stageTwoLoads)) as {
				partStoreLocations: { data: { locations: LIB_storeLocations$result['locations'] } }
				trueModels?: Array<PartModel>
				assyModels?: Array<PartModel>
				vehicleModels?: Array<string>
			}

			// It's technically possible to have a cached part and not a cached optionValueMap
			const cachedOptionValueMap = getObject<{
				keyParts: Array<'optionId' | 'serialId' | 'serialUuid'>
				entries: ReturnType<OptionValueMap['entries']>
			}>(localStorage, 'cachedOptionValues')
			const optionValueMap = loadCachedPart && cachedOptionValueMap ? new ObjectMap(cachedOptionValueMap.keyParts, cachedOptionValueMap.entries) : getOptionValueMap(part)

			part.glCategory = stageOneData.glCategories.find(cat => cat.id === part.glCategory?.id) ?? null
			part.saleClass = stageOneData.saleClasses.find(saleClass => saleClass.code === part.saleClass?.code) ?? part.saleClass

			part.userStatus ||= null
			part.coreClass ||= null

			const res = {
				settingValues,
				partStoreLocations: partStoreLocations.data?.locations ?? [],
				origPart: klona(part),
				part,
				attachments,
				partStore: writable(part),
				optionValueMap,
				loadCachedPart,
				partChanged: loadCachedPart,
				sellPriceClasses: stageOneData.sellPriceClasses.sort((a, b) => {
					const aDisplayName = a.parent?.name ? `${a.parent.name} - ${a.name}` : a.name
					const bDisplayName = b.parent?.name ? `${b.parent.name} - ${b.name}` : b.name
					return aDisplayName.localeCompare(bDisplayName)
				}),
				trueModels: trueModels ?? [],
				assyModels: assyModels ?? [],
				storeId,
				tab,
				typeFields: computeTypeFields(part),
				glCategories: stageOneData.glCategories,
				inventoryConditions: stageOneData.inventoryConditions,
				inventoryTypeList: stageOneData.inventoryTypeList.map(type => ({
					...type,
					manufacturerList: type?.partManufacturers.sort((a, b) => a.name.localeCompare(b.name)) ?? [],
				})),
				saleClasses: stageOneData.saleClasses,
				userPartStatusList: stageOneData.userPartStatusList,
				userVehicleStatusList: stageOneData.userVehicleStatusList,
				vehicleMakes: stageOneData.vehicleMakes,
				// maybe we could filter this better on the server, idk
				vendorList: stageOneData.vendors.items.filter(vendor => vendor?.code ?? vendor?.companyName ?? vendor?.contactName) ?? [],
			}
			console.log('resolve', res)
			return res
		},
	})
}
const inventoryQueryByIdStore = graphql(`
	query HOUDINI_inventoryQuery($innodbInventoryid: UInt!, $serialFilter: InventorySerialFilter) {
		inventory(id: $innodbInventoryid) {
			...InventoryData @with(serialFilter: $serialFilter)
		}
	}
`)
// looks like this is used in a couple places, maybe we want to call it somewhere else? Or just make it shareable?
const settingValuesQuery = graphql(`
	query LIB_settingValues {
		settingValues {
			vehicle {
				userStatusRequired
				minVinLength
				mileageRequired
				flexField4Required
				flexField3Required
				flexField2Required
				flexField1Required
				conditionRequired
			}
			inventory {
				defaultNonReplenishablePartsArePublic
				categoryEditable
				categoryRequired
				categoryVisible
				conditionEditable
				conditionRequired
				conditionVisible
				coreCostEditable
				coreCostRequired
				coreCostVisible
				costEditable
				costRequired
				costVisible
				defaultDaysToReturn
				defaultDaysToReturnCore
				defaultDaysToReturnCoreToVendor
				defaultDaysToReturnToVendor
				defaultLocationName
				defaultQuantityForMisc
				defaultQuantityForReplenishable
				defaultQuantityForStandard
				defaultReplenishablePartsArePublic
				defaultReturnable
				defaultGlCategoryId
				defaultNonReplenishablePartsArePublic
				defaultReturnableToVendor
				distributorCorePriceEditable
				distributorCorePriceRequired
				distributorCorePriceVisible
				distributorPriceEditable
				distributorPriceRequired
				distributorPriceVisible
				glCategoryEditable
				glCategoryRequired
				glCategoryVisible
				inventoryTypeEditable
				inventoryTypeRequired
				inventoryTypeVisible
				jobberCorePriceEditable
				jobberCorePriceRequired
				jobberCorePriceVisible
				jobberPriceEditable
				jobberPriceRequired
				jobberPriceVisible
				listPriceEditable
				listPriceRequired
				listPriceVisible
				oemNumberEditable
				oemNumberRequired
				oemNumberVisible
				retailPriceEditable
				retailPriceRequired
				retailPriceVisible
				retailCorePriceEditable
				retailCorePriceRequired
				retailCorePriceVisible
				sideEditable
				sideRequired
				sideVisible
				userStatusEditable
				userStatusRequired
				userStatusVisible
				varianceLocationName
				vendorEditable
				vendorRequired
				vendorVisible
				wholesalePriceEditable
				wholesalePriceRequired
				wholesalePriceVisible
				wholesaleCorePriceEditable
				wholesaleCorePriceRequired
				wholesaleCorePriceVisible
			}
			fileAttachments {
				maxImageHeight
				maxImageWidth
			}
			location {
				enforceLocationHierarchy
			}
		}
	}
`)

const stageOneQuery = graphql(`
	query PartScreenStageOne {
		inventoryConditions
		userPartStatusList: userStatuses(type: INVENTORY) {
			type
			status
			trigger
			partStatus
			description
		}
		userVehicleStatusList: userStatuses(type: VEHICLE) {
			type
			status
			trigger
			partStatus
			description
		}
		inventoryTypeList: inventoryTypes(active: true) {
			inventoryTypeId: id
			typeSetId: setId
			name
			vehicle
			categories {
				id
				name
				description
			}
			partManufacturers: manufacturers {
				id
				name
				code
			}
			requireSerialization
		}
		glCategories {
			id
			name
		}
		vehicleMakes
		vendors(pagination: { pageSize: 0 }) {
			items {
				id
				# active
				code
				companyName
				contactName
				# stockVendor
			}
		}
		sellPriceClasses {
			id
			name
			parent {
				id
				name
			}
		}
		saleClasses {
			code
			name
		}
	}
`)

const partStoreLocationsQuery = graphql(`
	query LIB_storeLocations($storeIds: [Int!]) {
		locations(filter: { allowInventory: true, storeIds: $storeIds }) {
			id
			name
			description
		}
	}
`)
