export default {
    "name": "LIB_updateInventoryBasic",
    "kind": "HoudiniMutation",
    "hash": "1d167b359931660efe8e507ee56e76848cf8df1699ea1b7ce2a03e97e1789f70",

    "raw": `mutation LIB_updateInventoryBasic($part: InventoryUpdate!) {
  part: updateInventory(input: $part) {
    innodbInventoryid: id
    storeId
    sku
    id
  }
}
`,

    "rootType": "Mutation",

    "selection": {
        "fields": {
            "part": {
                "type": "Inventory",
                "keyRaw": "part(input: $part)",

                "selection": {
                    "fields": {
                        "innodbInventoryid": {
                            "type": "UInt",
                            "keyRaw": "innodbInventoryid",
                            "visible": true
                        },

                        "storeId": {
                            "type": "UInt",
                            "keyRaw": "storeId",
                            "visible": true
                        },

                        "sku": {
                            "type": "UInt",
                            "keyRaw": "sku",
                            "visible": true
                        },

                        "id": {
                            "type": "UInt",
                            "keyRaw": "id",
                            "visible": true
                        }
                    }
                },

                "visible": true
            }
        }
    },

    "pluginData": {
        "houdini-svelte": {
            "isManualLoad": true
        }
    },

    "input": {
        "fields": {
            "part": "InventoryUpdate"
        },

        "types": {
            "NestedNewInventoryAttachment": {
                "file": "Upload",
                "fileId": "UInt",
                "public": "Boolean",
                "rank": "Int"
            },

            "InventoryAttachmentUpdate": {
                "id": "Int",
                "public": "Boolean",
                "rank": "Int"
            },

            "NestedInventoryAttachmentUpdates": {
                "create": "NestedNewInventoryAttachment",
                "remove": "Int",
                "update": "InventoryAttachmentUpdate"
            },

            "NestedNewInventoryLocation": {
                "locationId": "Int",
                "locationName": "String",
                "permanent": "Boolean",
                "quantity": "Decimal6",
                "rank": "Int"
            },

            "NestedInventoryLocationUpdate": {
                "id": "Int",
                "permanent": "Boolean",
                "quantity": "Decimal6",
                "rank": "Int"
            },

            "InventoryLocationUpdates": {
                "create": "NestedNewInventoryLocation",
                "update": "NestedInventoryLocationUpdate"
            },

            "InventoryOptionValueUpdate": {
                "inventoryOptionId": "Int",
                "value": "String"
            },

            "NestedInventorySerialUpdate": {
                "action": "ActionEnum",
                "id": "Int",
                "location": "String",
                "number": "String",
                "optionValues": "InventoryOptionValueUpdate"
            },

            "DimensionsInput": {
                "height": "Decimal2",
                "length": "Decimal2",
                "measurementUnit": "DimensionsMeasurementUnit",
                "weight": "Decimal6",
                "weightUnit": "DimensionsWeightUnit",
                "width": "Decimal2"
            },

            "InventoryUpdate": {
                "attachments": "NestedInventoryAttachmentUpdates",
                "bodyStyle": "String",
                "buyPackage": "Int",
                "categoryId": "Int",
                "condition": "String",
                "coreClass": "String",
                "coreCost": "Decimal2",
                "coreRequired": "Boolean",
                "coreRequiredToVendor": "Boolean",
                "cost": "Decimal2",
                "daysToReturn": "UInt",
                "daysToReturnCore": "UInt",
                "daysToReturnCoreToVendor": "UInt",
                "daysToReturnToVendor": "UInt",
                "deplete": "Boolean",
                "description": "String",
                "distributorCorePrice": "Decimal2",
                "distributorPrice": "Decimal2",
                "freezeUntil": "Date",
                "glCategoryId": "Int",
                "id": "UInt",
                "interchangeNumber": "String",
                "jobberCorePrice": "Decimal2",
                "jobberPrice": "Decimal2",
                "listPrice": "Decimal2",
                "locations": "InventoryLocationUpdates",
                "manufacturerId": "UInt",
                "maxQuantity": "Decimal6",
                "minQuantity": "Decimal6",
                "modelId": "UInt",
                "notes": "String",
                "oemNumber": "String",
                "optionValues": "InventoryOptionValueUpdate",
                "parentManufacturerId": "UInt",
                "parentModelId": "UInt",
                "partNumber": "String",
                "popularityCode": "String",
                "printTag": "Boolean",
                "public": "Boolean",
                "purchaseFactor": "Boolean",
                "quantity": "Decimal6",
                "replenishable": "Boolean",
                "retailCorePrice": "Decimal2",
                "retailPrice": "Decimal2",
                "returnable": "Boolean",
                "returnableToVendor": "Boolean",
                "safetyStockPercent": "Decimal2",
                "saleClassCode": "String",
                "saleClassId": "Int",
                "seasonal": "Boolean",
                "sellPackage": "Int",
                "sellPriceClassId": "Int",
                "serialized": "Boolean",
                "serials": "NestedInventorySerialUpdate",
                "shippingDimensions": "DimensionsInput",
                "side": "SideEnum",
                "singleQuantity": "Boolean",
                "status": "StatusEnum",
                "stockMethod": "StockMethodEnum",
                "stockType": "StockTypeEnum",
                "stockingDays": "Int",
                "subInterchangeNumber": "String",
                "tagNumber": "String",
                "taxable": "Boolean",
                "typeData1": "String",
                "typeData2": "String",
                "typeData3": "String",
                "typeData4": "String",
                "userStatus": "String",
                "vehicleId": "Int",
                "vehicleMake": "String",
                "vehicleModel": "String",
                "vehicleVin": "String",
                "vehicleYear": "UInt",
                "vendorPopularityCode": "String",
                "vendorProductCode": "String",
                "wholesaleCorePrice": "Decimal2",
                "wholesalePrice": "Decimal2"
            }
        },

        "defaults": {},
        "runtimeScalars": {}
    }
};

"HoudiniHash=4e50d2b88bd984a9eba439a6ab58a4e5930efa9e8daf8f79e6108b6ea0820758";